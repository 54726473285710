/**
 * Off Canvas Element
 *
 */

function handleClick(toggle, offCanvasElement) {
  console.log('clicked', toggle.classList);

  if (toggle.classList.contains('open')) {
    toggle.classList.remove('open');
    offCanvasElement.classList.remove('translate-nav-to-the-left');
  } else {
    toggle.classList.add('open');
    offCanvasElement.classList.add('translate-nav-to-the-left');
  }
}

const addClickListener = (el, target) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    handleClick(el, target);
  });
};

function toggleMobileMenu(toggle, target) {
  addClickListener(toggle, target);
}

const init = (options = {}) => {
  if (!options.toggle && !options.target) {
    throw new Error(
      'OffCanvasElement can not be initialised, required options are missing'
    );
  }

  const { toggle, target } = options;

  const toggleEls = [].slice.call(document.querySelectorAll(toggle));
  const offCanvasTarget = document.querySelector(target);
  // Fail silently if no elements are found
  if (!toggleEls.length || !offCanvasTarget) return;

  return toggleEls.map(function (el) {
    return toggleMobileMenu(el, offCanvasTarget);
  });
};

export default init;
