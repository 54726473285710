import animateScrollTo from 'animated-scroll-to';

function init(selector = '.smooth-scroll') {
  console.log('init smooth scroll', selector);

  let scrollOffset = 0;

  const mainNavEl = document.querySelector('.site-header');
  const subNavEl = document.querySelector('.funds-navbar');

  scrollOffset = parseInt(
    (mainNavEl ? mainNavEl.offsetHeight : 0) +
      (subNavEl ? subNavEl.offsetHeight : 0)
  );

  // Find all elements that have the 'smooth-scroll' class
  const smoothScrollElements = document.querySelectorAll('.smooth-scroll');

  if (smoothScrollElements.length === 0) {
    return;
  }

  // Loop through each element and add a click event listener

  smoothScrollElements.forEach((element) => {
    element.addEventListener('click', (event) => {
      console.log('clicked on smooth scroll element');
      event.preventDefault();

      // Get the target element's id
      const targetId = element.getAttribute('href');
      console.log('targetId', targetId);

      const url = new URL(targetId, window.location.href);
      const curUrl = new URL(window.location.href);
      console.log('smooth scroll url', url);
      console.log('current url', curUrl);

      if (
        `${url.origin}${url.pathname}` !== `${curUrl.origin}${curUrl.pathname}`
      ) {
        // navigate to the new url
        console.log('ignoring smooth scroll, navigating to new url', url.href);
        window.location.href = url;
        return;
      }

      // Get the target element's position
      const targetPosition = document.querySelector(url.hash);

      if (
        targetPosition &&
        `${url.origin}${url.pathname}` === `${curUrl.origin}${curUrl.pathname}`
      ) {
        console.log('Smooth scrolling');
        // Scroll to the target element
        animateScrollTo(targetPosition.offsetTop, {
          speed: 700,
          verticalOffset: -scrollOffset + 1,
          maxDuration: 1000,
        });
      }
    });
  });
}

export default init;
