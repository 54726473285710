import './main.scss';
import offCanvasElement from './components/off-canvas-element';
import smoothScroll from './components/smooth-scroll';

window.gc_site = {
  init() {
    offCanvasElement({
      toggle: '.toggleMobileMenu',
      target: '#genesis-nav-primary',
    });

    smoothScroll('.smooth-scroll');
  },
};

function getComponents() {
  // video overlay dom element
  if (document.getElementById('videoOverlayContainer')) {
    import(
      /* webpackChunkName: "videoOverlay" */ './components/video-overlay/video-overlay'
    )
      .then((module) => {
        module.default();
      })
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the video overlay component \n' +
            error.message
        );
      });
  }

  //  show more's
  if (document.querySelector('.show-more')) {
    import(
      /* webpackChunkName: "showMore" */ './components/show-more/show-more'
    )
      .then((module) => module.default('.show-more'))
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the show more component \n' +
            error.message
        );
      });
  }

  //  side navs to highlight on scroll
  if (document.querySelector('.sidebar-nav')) {
    import(
      /* webpackChunkName: "highlightOnScroll" */ './components/highlight-on-scroll/highlight-on-scroll'
    )
      .then((module) => module.default('.sidebar-nav'))
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the highlight on scroll component \n' +
            error.message
        );
      });
  }

  //  Doughnut charts
  if (document.querySelector('.fund-doughnut-graph')) {
    import(
      /* webpackChunkName: "graphDoughnut" */ './components/graphs/doughnut-graph'
    )
      .then((module) => module.default('.fund-doughnut-graph'))
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the fund doughnut graph component \n' +
            error.message
        );
      });
  }

  //  line charts
  if (document.querySelector('.fund-line-graph')) {
    import(/* webpackChunkName: "graphLine" */ './components/graphs/line-graph')
      .then((module) => module.default('.fund-line-graph'))
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the fund line graph component \n' +
            error.message
        );
      });
  }

  //  Country Cookie Consent Modal
  if (document.querySelector('.cookie-country-modal-container')) {
    import(
      /* webpackChunkName: "cookieCountryModal" */ './components/country-cookie-consent'
    )
      .then((module) => module.default())
      .catch(
        (error) => 'An error occurred while loading the Country Cookie Consent'
      );
  }

  //  Searchable select (choices.js)
  if (document.querySelector('.searchable-select')) {
    import(
      /* webpackChunkName: "searchableSelectl" */ './components/searchable-select'
    )
      .then((module) => module.default('.searchable-select'))
      .catch(
        (error) => 'An error occurred while loading the Country Cookie Consent'
      );
  }

  //  sliders
  if (document.querySelector('.gc-slider')) {
    import(/* webpackChunkName: "slider" */ './components/slider/slider')
      .then((module) => module.default('.gc-slider'))
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the slider component \n' +
            error.message
        );
      });
  }

  //  archive filters
  if (document.querySelector('.archive-filters')) {
    import(
      /* webpackChunkName: "archiveFilter" */ './components/archive/archive-filter'
    )
      .then((module) => module.default('.archive-filters'))
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the archive filter component \n' +
            error.message
        );
      });
  }

  //  Tabbed content
  if (document.querySelector('.tabbed-content')) {
    import(/* webpackChunkName: "tabbedContent" */ './components/tabs/tabs')
      .then((module) => module.default('.tabbed-content'))
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the tabbed content component \n' +
            error.message
        );
      });
  }

  //  YARPP Link Fixer
  if (document.querySelector('.yarpp-related')) {
    import(/* webpackChunkName: "tabbedContent" */ './components/yarpp')
      .then((module) => module.default('.yarpp-related'))
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the yarpp link fixer component \n' +
            error.message
        );
      });
  }

  // End of object
}

document.addEventListener('DOMContentLoaded', () => {
  getComponents();

  try {
    gc_site.init();
  } catch (error) {
    // Global error handler
    console.error(error);
  }
});
